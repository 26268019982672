export default {
  GITHUB_REPO_URL: "https://github.com/skyflowapi/creditagency-sample-app",
  VAULT_ID: "h25daf2db56b4007b71c95b91570ca14",
  ORG_NAME: 'sandbox1_default_workspace',
  ACCOUNT_NAME: 'sandbox1',
  VAULT_URL:"https://a370a9658141.vault.skyflowapis-preview.com"  
  };
  
  // Prod
  //   VAULT_ID: process.env.VAULT_ID || "eee76dee3a654b38aa0ca504ff9679c1",
  //   ORG_NAME: process.env.ORG_NAME || 'na1',